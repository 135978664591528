import $api from "../http";
import qs from 'qs';
import * as iso88592 from 'iso-8859-2';
import {decode} from 'html-entities';
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";



export default class UserService {
    static async checkUserAuth(tg_id: string) {
        return $api.get(`/bot/user/check/${tg_id}`)
    }

    static async writeUserHistory(tg_id: string, categoryName: string, href: string, type: string) {
        return $api.post(`/bot/user/history`, {
            tg_id: tg_id,
            action: categoryName,
            href: href,
            type: type,
        })
    }

    static async getMetaByKey(key: any) {
        let response = await $api.get(`/bot/field/${key}`, {
            responseType: 'text',
            withCredentials: false,
            // responseEncoding: 'binary'
        });
        // console.log()

        

        return response.data;
    }
}