import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import categoriesStore from "../store/categoriesStore";
import config from "../config";
import userStore from "../store/userStore";

interface Category {
    id: number,
    parent: number,
    title: string,
    image: string,
    isPost: number,
    content: string,
    description: string,
    path: string,
}

interface CategoryListProps {

}

const CategoryList:FC<any> = ({tgid}: any) => {
    let { id } = useParams();

    const CategoriesStore = new categoriesStore;
    const [category, setCategory] = useState<Category[]>([]);
    const [parent, setParent] = useState<Category>();
    const [mainText, setMainText] = useState('');

    const sendEndpoint = async (e:any, title: any, href: any) => {
        // e.preventDefault();
        // userStore.tele.openLink(href);

        userStore.writeUserHistory(tgid, `${title}`, href, 'endpoint')
    }

    const loadText = async () => {
        setMainText(await userStore.getMetaByKey('htmlIndexText'))
    }


    useEffect(() => {

        CategoriesStore.getCategory(id?id:0, tgid).then((result:any) => {
            if (id) CategoriesStore.getCurrentCategory(id).then((res: any) => {
                setParent(res)
            })

            if (!id || id == '0') {
                loadText()
            }

            setCategory(result);
        });

    }, [id])


    return (
        <ul className="category__list">

            {parent && 
                <>
                    <h1 className="category__title">
                        <NavLink className="category__back-link" to={`/${parent.parent}`}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAABmJLR0QA/wD/AP+gvaeTAAAAw0lEQVRoge3aywqCUBRG4UUP2I3CQb17UFCBj2BgAxupzdpuzml94PxfKMIRQZIkaWoHPIAWOCRvCdcAHdB/rjZ3TqxxbA88UxcFmovtgH3mqChHprEv4JQ5KoqxGFs+YzG2fMZibPmMxdjyGYux5dsyf3hvMkdFulFA7Cp7QMn+7pGG4fvx3EvrnDkqmtEYXS+jMbpeRmN0vYzG6HoZjdH1+vZTyyZzVLS56GvqogWMo++5c5axAS4Md3edvEWSJP3AG0pD58yf2hdeAAAAAElFTkSuQmCC" alt="" /></NavLink>
                        {parent.title}
                    </h1>
                    { parent.description && parent.description.length > 3 &&
                        <h2 className="category__description" dangerouslySetInnerHTML={{__html: parent.description}}></h2>
                    }
                </>
            }

            { ( !id || id == '0' ) &&
                <h2 className="category__description" dangerouslySetInnerHTML={{__html: mainText}}></h2>
            }

            {!parent && 
                <div className="category__title-placeholder"></div>
            }

            {category.length>0?category?.map((item) => {
                return (
                    <li key={item.id}>
                        {item.isPost == 0 && 
                            <NavLink className="category__link" to={`/${item.id}`} /*onClick={() => setParent(item)} */>
                                { item.image.length > 0 && 
                                    <img src={`${config.API_URL}${item.image}`} className="category__image" />
                                }
                                {item.title}
                            </NavLink>
                        }

                        {item.isPost == 1 && 
                            <a href={`${item.content}`} className="category__link" target="_blank" onClick={(e) => {sendEndpoint(e, item.title, item.content)}}>
                                { item.image.length > 0 && 
                                    <img src={`${config.API_URL}${item.image}`} className="category__image" />
                                }
                                {item.title}
                            </a>
                        }
                    </li>
                )
            }):false}
        </ul>
    )
}

export default observer(CategoryList);