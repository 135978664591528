import $api from "../http";

export default class CategoriesService {
    static async getCategory (id:any, tgid:any) {
        return $api.post(`/category/${id}`, {tg_id: tgid})
    }

    static async getCategoryInfo(id:any) {
        return $api.get(`/category/${id}/info`) 
    }
}