import categoriesService from "../services/categoriesService";

export default class categoriesStore {
    async getCategory(id:any, tgid: any) {
        try {
            return (await categoriesService.getCategory(id, tgid)).data;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getCurrentCategory(id:any) {
        try {
            return (await categoriesService.getCategoryInfo(id)).data;
        } catch (e) {
            console.log(e)
            return false;
        }
    }
}