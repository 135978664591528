import UserService from "../services/userService";

declare global {
	interface Window {
		Telegram?: any;
	}
}

class UserStore {
    tele: any;

    constructor() {
        this.tele = window.Telegram.WebApp;

        // this.tele.sendData({
        //     data: '123',
        // });
    }

    async checkUserAuth(tg_id: string) {
        const id = (await UserService.checkUserAuth(tg_id)).data;
        if (id) {
            return tg_id;
        }

        return false;
    }

    async writeUserHistory(tg_id: string, text: string, href: string, type: string) {
        await UserService.writeUserHistory(tg_id, text, href, type);
    }

    async getMetaByKey(key:any) {
        return await UserService.getMetaByKey(key);
    }
}

export default new UserStore()