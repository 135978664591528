import React, { FC, useEffect, useState } from 'react';
import './reset.css';
import './App.scss';
import { Routes, Route } from "react-router-dom"
import CategoryList from './components/CategoryList';
import { observer } from 'mobx-react-lite';
import userStore from './store/userStore';
import $api from './http';
// import { useStartParam } from 'react-telegram-webapp';
const tele = window.Telegram.WebApp;

const App:FC = () => {
	// const userStore = new UserStore();
	const [tgData, setTgData] = useState<any>();
	const [tgId, setTgId] = useState<any>();
	const [loading, setLoading] = useState<boolean>(true);
	const [errorText, setErrorText] = useState('');

	let tele = userStore.tele;

	useEffect(() => {
		tele.ready()
	});

	useEffect(() => {
		if (tele.initDataUnsafe) {
			setTgData(tele.initDataUnsafe.user);
			checkUser(tele.initDataUnsafe.user);

			// await userStore.writeUserHistory(tgId, 'Откры')
		}

		
		startConfig();
	}, [])

	const startConfig = async () => {
		setErrorText(await userStore.getMetaByKey('htmlErrorAuth'))
	}

	// Проверяем, есть ли номер телефона пользователя в базе врачей, если пользователь вообще передал номер телефона
	const checkUser = async (data:any) => {
		if (!data) {
			setLoading(false);
			return false;
		}
		const isAuth = await userStore.checkUserAuth(data.id);
		if (isAuth) {
			setTgId(isAuth);
		}

		// setTgId(true);

		setLoading(false);
	}


	if (loading) {
		return (
			<div className="content">
				<div className="content__loader">
					<div className="loader"></div>	
				</div>
			</div>
		)
	}

	if (!tgId) {
		return (
			<div className="content">
				<div className="no-access">
					<div dangerouslySetInnerHTML={{__html: errorText}}></div>
				</div>
			</div>
		)
	}

	return (
		<div className="content">
			{/* <div>{ tgData!=undefined?JSON.stringify(tgData.id):'' }</div> */}
			{/* <div>{tgId}</div> */}
			<Routes>
				<Route path="/" element={<CategoryList tgid={tgId} />}/>
				{/* <Route path="/:tgid" element={<CategoryList />}/> */}
				<Route path="/:id/" element={<CategoryList tgid={tgId} />}/>
			</Routes>
		</div>
	);
}

export default observer(App);
